<template>
	<div>
		<div>
			<!-- <el-button :disabled="getData.dis" style="float: right;margin-bottom:20px;" size="small" type="primary" @click="clickManualStroke">交易划转</el-button> -->
		</div>
		<el-table :data="getData.manageList" border style="width: 100%;margin-bottom:40px;"
			:header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
			<el-table-column prop="type" label="费用类型" :formatter="type" />
			<el-table-column prop="dailyAccrual" label="管理费(每日)" />
			<el-table-column prop="sumAccrual" label="累计管理费" />
			<el-table-column prop="payable" label="应缴" />
		</el-table>

		<div>
			<PriceList></PriceList>
		</div>

		<el-dialog v-model="getData.opinion" title="交易划转" width="50%">
			<div>
				<el-table :data="getData.userList" border style="width: 100%"
					:header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
					<el-table-column prop="userName" label="姓名" />
					<el-table-column>
						<template #default="scope" prop="amounts">
							<el-input
							 onkeyup="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+5)}"
							 onkeypress='return( /[\d]/test(String.fromCharCode(event.keyCode)))'
								v-model="scope.row.amounts" 
								@input="amountTransferred" 
								type="text">
							</el-input>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="getData.opinion = false">取消</el-button>
					<el-button :type="getData.disabled ? 'error' : 'primary' " :disabled = "getData.disabled" @click="doManualStroke">确定</el-button>
				</span>
			</template>
		</el-dialog>



	</div>
</template>
<script setup>
import dayjs from "dayjs";
import { ref, onMounted, reactive, onActivated, defineExpose } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { listManageFee, manualStroke, listGroupUser } from '../fundApi.js';
import PriceList from '../../../../components/OpenFund/priceList.vue'//申请
let getData = reactive({
	manageList: [],
	userList: [],
	dis: ref(false),
	disabled: ref(false)
})
let opinion = ref(false);
onMounted(() => {
	transactionTransfer()
})
const type = function (row) {
	if (row.type == '1') {
		return "管理费";
	} else if (row.type == '2') {
		return "托管费";
	}
}
const transactionTransfer = () => {
	if (JSON.parse(sessionStorage.getItem('classInfo')).roleName === '基金经理') {
		getData.dis = false
	} else {
		getData.dis = false
	}
	// else if () {}
}

// 交易划转金额判断
const amountTransferred = (value) => {
	getData.manageList.forEach((item) => {
		if (item.type == '1') {
			if (value <= 0) {
				getData.disabled = true;
					return ElMessage({ type: 'error', message: '不能小于等于0!' })
			} else if (value > item.payable) {
				getData.disabled = true;
				return ElMessage({ type: 'error', message: '不能超出应缴金额!' })
			}
			getData.disabled = false;
		}

	})
}
const manageFee = () => {
	var formData = new FormData();
	formData.append("groupId", JSON.parse(sessionStorage.getItem("groupId")));
	listManageFee(formData).then(res => {
		getData.manageList = res.data.data;
		if (res.data.data.length == 0) {
			getData.dis = true;
		}

		res.data.data.forEach(item => {
			if (item.updateTime == null) {
				getData.dis = false
			}
			// 判断列表的数据日期是否在当前月，如果在当前月交易划转按钮禁用，如果不在则可交易划转
			item.updateTime = dayjs(item.updateTime).format('YYYY-MM-DD')
			const dateObj = new Date(Date.parse(item.updateTime));
			const currentDate = new Date();
			const currentMonth = currentDate.getMonth();
			if (dateObj.getMonth() === currentMonth) {
				// 是当前月份
				getData.dis = true
			} else {
				// 不是当前月份
				getData.dis = false
			}
		})

	});
}

const clickManualStroke = () => {
	var formData = new FormData();
	formData.append("groupId", JSON.parse(sessionStorage.getItem("groupId")));
	listGroupUser(formData).then(res => {
		getData.userList = res.data.data;
	});
	getData.opinion = true;
}
const doManualStroke = () => {
	let paymentAmount = 0;
	paymentAmount = 0;
	getData.userList.forEach((item) => { 
		paymentAmount = paymentAmount + Number(item.amounts)
	})
	if (paymentAmount > getData.manageList[0].payable) {
			ElMessage({ type: 'error', message: '总额度超出应缴金额!' })
			return  
	}


	let parm = {
		"acid": JSON.parse(sessionStorage.getItem("acid")),
		"manageFee": getData.manageList[0].payable,
		"custodyFee": getData.manageList[1].payable,
		"users": getData.userList,
		"groupId": JSON.parse(sessionStorage.getItem("groupId"))
	};
	
	manualStroke(parm).then(res => {
		if (res.data.code == '200') {
			manageFee()
			ElMessage({ type: 'success', message: '操作成功！' })
		} else {
			ElMessage({ type: 'error', message: res.data.msg })
		}
	});
	getData.opinion = false;
}


defineExpose({
	manageFee,
	clickManualStroke
});



</script>