<template>
  <div style="color:#606266;">
    <!-- <p>以下费用用在认购/申购/赎回基金过程中收取：</p> -->
    <!-- <p>交易费用：</p> -->
    <!-- <span class="p" style="display: inline-block;width: 80px;text-align: right;">交易费用：</span> -->
    <div style="">
      <table>
        <tr>
          <th colspan="3" style="font-size:18px">交易费用</th>
        </tr>
        <tr>
          <th>费用类型</th>
          <th>份额(S)或金额(M)/持有期限(N)</th>
          <th>收费方式/费率</th>
        </tr>
        <tr>
          <th rowspan="3">申购费(前收费)</th>
          <td> <span>M&lt;1.000.000 </span>
          </td>
          <td> <span>1.5%</span> </td>
        </tr>
        <tr>
          <td><span>1,000,000&lt;=M&lt;5,000,000 </span>
          </td>
          <td><span> 1.2% </span></td>
        </tr>
        <tr>
          <td><span> 5,000,000&lt;=M </span></td>
          <td><span> 1000元/笔</span></td>
        </tr>
        <tr>
          <th rowspan="5">赎回费</th>
          <td> <span> N&lt;7日 </span> </td>
          <td><span> 1.5% </span></td>
        </tr>
        <tr>
          <td><span>7&lt;=N&lt;30日 </span></td>
          <td><span> 0.75% </span></td>
        </tr>
        <tr>
          <td><span> 30&lt;=N&lt;365日 </span></td>
          <td><span> 0.5%</span></td>
        </tr>
        <tr>
          <td><span> 365&lt;=N&lt;730日 </span></td>
          <td><span> 0.25% </span></td>
        </tr>
        <tr>
          <td><span> 730&lt;=N日 </span></td>
          <td><span> 0 </span></td>
        </tr>
        <tr>
          <th colspan="3" style="font-size:18px">其它费用</th>
        </tr>
        <tr>
          <th>费用类别</th>
          <th colspan="2"> <span> 收费方式/年率费 </span> </th>
        </tr>
        <tr>
          <th>管理费</th>
          <td colspan="2"> <span> 1.50% </span> </td>
        </tr>
        <tr>
          <th>托管费</th>
          <td colspan="2"> <span> 0.25% </span> </td>
        </tr>
      </table>
    </div>

    <!-- <div style="width: 760px">
    <div style="display: flow-root">
      <div style="display: flex">
        <div class="divTd">费用类别</div>
        <div class="divTd1" style="text-align: center">收费方式/年率费</div>
      </div>
      <div style="display: flex">
        <div class="divTd">管理费</div>
        <div class="divTd1"> 1.50% </div>
      </div>
      <div style="display: flex">
        <div class="divTd">托管费</div>
        <div class="divTd1">0.25%</div>
      </div>
    </div>
  </div> -->

  </div>
</template>

<style scoped>
p {
  margin-bottom: 10px;
  font-size: 14px;
}

h4 {
  margin: 15px 0 0 0;
}

table {
  border-collapse: collapse;
  border: 1px solid;
}

tr,
td,
th {
  display: 'none';
  border: 1px solid;
  margin-top: '40px';
  width: 280px;
  height: 25px;
  line-height: 25px
}

th {
  font-size: 13px;
}

td {
  font-size: 12px;
}


.divTd {
  width: 252px;
  border: 1px solid #000;
  border-top: 0px;
  text-align: center;
  font-weight: 700;
}

.divTd1 {
  flex: 1;
  border: 1px solid #000;
  border-top: 0px;
  border-left: 0;
  text-align: center;
}

span {
  text-align: center;
  display: block;
}

.p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #03032C;
}
</style>